export const onKeydown = (e) => {
  e = e || window.event;

  if (e.keyCode === 37) 
    document.getElementById('button-back')?.click()
  else if (e.keyCode === 39) 
    document.getElementById('button-next')?.click()
}

export const scrollTop = (timer=400) => {
  let container = document.querySelector('#container')
  
  setTimeout(() => {
    container.scrollTo({top: 0, behavior: 'smooth'});
  }, timer);
}